$linkColor:#FF19A9;//rgb(248, 68, 102);
$fontColor:#ffffff;
$background:#171717;
$gradientBackground1:#FF19A9;
$gradientBackground2:#C2E600;
$fontSize:2.8rem;

:root {
  --scroll: 0;
}

.App {
  text-align: center;
}

.bg-vid { 
  position: fixed; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  object-fit: cover; 
  z-index: -1; 
  // mix-blend-mode:soft-light;
  mix-blend-mode:hard-light;
  opacity: 6%;
  // filter: blur(22px);
  
}

.bg-vid2 { 
  position: sticky; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  object-fit: cover; 
  z-index: -1; 
  // mix-blend-mode:soft-light;
  // mix-blend-mode:hard-light;
  opacity: 30%;
  
  
}




@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    // animation: App-logo-spin infinite 20s linear;  
    transform: scale(0.7);
    margin-bottom: -100px;
  }
}

body{
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  background-color: $background;
  margin: 0;
  padding: 0;

  letter-spacing: .1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // line-height: 60px;
}

a {
  color: $linkColor;
  font-weight: 700;
  text-decoration: solid;
  a:hover{
    text-decoration: underline;
  }
}

.App-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $fontColor;
  user-select: none;
  
  background: linear-gradient(-60deg, rgba(#8174FF, .0) 25%, rgba($gradientBackground1, .2) 100%);
  mix-blend-mode:screen;
  ////////////////////
  // position: -webkit-sticky; /* Safari */
  // position: sticky;
  top: 0;
  left: 0;

  // background: linear-gradient(-60deg, rgba($gradientBackground2, 0.5) 0%, rgba($gradientBackground1, 0.5) 100%);
  // mix-blend-mode:color-dodge;

  z-index: 1;
  height: 95vh;
  ///////////////////
  /// 
  
  // translate: 0 calc( ( -1% * max( var( --scroll ), 25 ) - 25 ) * 100 / 75 );
  // --background-opacity:  0 calc( 1% * max( var( --scroll ), 30 ) * 100 / 30 );
   
    p{
      white-space: pre-wrap;
      font-weight: 300;
      max-width: 80%;
      font-size: 46px;
    }
    h4{
      display: flex;
      font-weight: 800;
      font-size: 11rem;
      line-height: 190px !important;
      letter-spacing: -.2rem !important;
      margin-top: -100px;
      margin-bottom: 120px;
    }
    h1{
      text-transform: capitalize;
      font-weight: 800;
      font-size: 11rem;
      line-height: 190px !important;
     //@extend .gradient-text;
      color: $gradientBackground1;
      // mix-blend-mode:hard-light;
     
      // height: 80vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 70%;
      
      // filter: blur(1px);
      letter-spacing: -.2rem !important;
      // filter: blur(calc(var(--scroll) * 0.1));
      // transition: filter 0.3s ease;
      // background: -webkit-linear-gradient($gradientBackground1, $gradientBackground2);
     
      // background: linear-gradient(-60deg, rgba($gradientBackground1, 1) 0%, rgba($gradientBackground2, 1) 100%);
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
     
     
      // @extend .noised;
      // @extend .shaking-text;
   


     
    }

    .strong{
      font-weight: 900;
    }

    .subTitle{
      font-size: 2.2rem;
      font-weight: 500;
      position: relative;
      top:-70px;
      
      // opacity: 40%;
      letter-spacing: 0rem !important;
      border-top: 1px solid  rgba(255, 255, 255, 0.2);
      border-bottom: 1px solid  rgba(255, 255, 255, 0.2);
      padding: 14px 0px;
      // background-color: rgba(255, 255, 255, 0.1);
      // backdrop-filter: blur(60px);
    }

   
    

}

.sectionTitle{
  //  font-weight: 700;
}

.description {
  font-size: 16px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  padding: 0 10%;
  margin-bottom: 30px;
}

.smallTitle{
  font-size: 22px;
}

.works{
  position: relative;
  top: -60px;
  z-index: 3;
  background-color: $background;
  font-size: $fontSize;
  font-weight: 700;
  color: $fontColor;
  padding-bottom:100px;
  // background: linear-gradient(-60deg, rgba(#8174FF, 1) 25%, rgba($gradientBackground1, 1) 100%);
 
  // }
  // background: linear-gradient(-60deg, rgba($gradientBackground2, 0.0) 0%, rgba($gradientBackground1, 0.1) 100%);
  

  span{
   
    
    video{
      // border: 1px solid #cccccc;
      box-shadow: 0px 3.605555534362793px 27.04166603088379px rgba(0, 0, 0, 0.05);
      border-radius: 22px;
      height: auto;
      z-index: 4;
    }
    
    h3{
      font-size: 2rem;
      span{
        font-size: 2rem;
        font-weight: 500;
      }
    }
  }

  h1{
    padding-top: 100px;
    padding-bottom: 50px;
  }
  
  .work{
    width: 80%;
  }
}

.scrollDown{
  font-size: 1rem;
  opacity: 70%;
  display: block;
}

.arrows{
    opacity: 70%;
    transform: translateX(-50%);
    animation: bounce 1s infinite;
    position: relative;
    top: 0;
    left: 0;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
  }
  40% {
      transform: translateY(-10px);
  }
  60% {
      transform: translateY(-5px);
  }
}


.about{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $background;
  font-size: $fontSize;
  font-weight: 300;

  ////////////////
  // position: -webkit-sticky; /* Safari */
  // position: sticky;
  top: 0;
  z-index: 2;
  background-color: white;



  // --value: min(var(--scroll), 30) * 50 / 30;
  // width: calc(50vw + 1vw * var(--value));
  // transform: translateX(calc(50% - 1% * var(--value)));

  // translate: 0 calc(-1% * (max(var(--scroll), 25) - 25) * 100 / 75);
  // --background-opacity: calc(100% - 1% * min(var(--scroll), 30) * 100 / 30);


  transform: rotate(calc(var(--scroll))deg);

  
  height: auto;
  padding: 50px 0;
  ///////////////
  /// 
  img{
    width: 20%;
  }
  spam{
    font-size: 10rem !important;
  }
  p{
    width: 80%;
    // line-height:4rem;
  }
}


.about-step2{
  
  
  color: $fontColor;
  // background: rgba(23, 23, 23, 0.2) url('./images/bg-about-2.png') no-repeat center center fixed;
  background-size: cover;
  display:block;
  background-color: $background;
  
  background: linear-gradient(-60deg, rgba(#8174FF, 1) 25%, rgba($gradientBackground1, 1) 100%);
  // mix-blend-mode:darken;
  // z-index: 3;
  span{
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;

    
   
  }
 h1{
 
  //  text-decoration: underline;
  // background: linear-gradient(-60deg, rgba($gradientBackground1, 0.8) 0%, rgba($gradientBackground2, 0.8) 100%);//linear-gradient(120deg, $gradientBackground1, $gradientBackground2);
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;

  font-weight: 900;
 }
}

.about-step3{

  color: $fontColor;
  // background: rgba(23, 23, 23, 0.2) url('./images/bg-about-2.png') no-repeat center center fixed;
  // background-size: cover;
  display:block;
  background-color: $background;
  background: linear-gradient(-60deg, rgba($gradientBackground2, 1) 25%, rgba($gradientBackground1, 1) 100%);
  // mix-blend-mode:darken;
  // z-index: 3;
  span{
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;

    
   
  }
 
 h1{
 
  //  text-decoration: underline;
  // background: linear-gradient(-60deg, rgba($gradientBackground1, 0.8) 0%, rgba($gradientBackground2, 0.8) 100%);//linear-gradient(120deg, $gradientBackground1, $gradientBackground2);
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;

  font-weight: 900;
 }
}


.primary{
  color:$gradientBackground1;
}
.secondary{
  color:$gradientBackground2;
}


.portfolio{
  ////////////////
  // position: -webkit-sticky; /* Safari */

  display: none;
  position: relative;
  top: -50px;

  z-index: 3;
  background-color: $background;
  
  ///////////////
  /// 
  font-size: $fontSize;
  font-weight: 700;
  color: $fontColor;
  p{
    height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  spam{
    font-weight: 300;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.1rem;
    max-width: 40%;
    margin-top: 30px;
 
  }

}

footer{

  ////////////////
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: -50px;
  z-index: 4;
  background-color: white;
  height: 100vh;
  ////////////////
  /// 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $background;
  
  font-weight: 300;
  p{
    width: 80%;
    font-size: 3rem;
    
  }
  h3{
    width: 80%;
    font-size: 1.4rem;
    font-weight: 300;
 
  }
}


.thumbs {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background-color:$background;
 
  a {
    display: inline-flex; // Make the <a> tag a flex container
    width: calc(25% - 10px); // Adjust the width based on your preferences, considering margins
    margin: 5px; // Add some margin for spacing

    img {
      aspect-ratio: 1 / 1;
      width: 100%;
      // max-width: calc(100vw/4);
      height: auto;
      object-fit: cover;
      opacity: 60%;
      // filter: grayscale(1);

     
    }

    &:hover img {
      opacity: 100%;
      filter: grayscale(0);
    }
  }
}

@media only screen and (max-width: 1200px) {
 
  .works{
    display: none;
  }
  .portfolio{
    display: block;
  }

  .App-header {
    display: flex;
      h1{
          font-size:4rem;
          line-height: 6rem !important;
      }
      h4{
        font-size: 4rem;
        line-height: 90px !important;
       
        margin-top: -50px;
        margin-bottom: 80px;
      }
      .subTitle{
        font-size: 1.3rem;
        
        top:-40px;

      }
  }
  .about{
    font-size:1.6rem !important;
    // margin-top: 100%;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    spam{
      font-size: 4rem !important;
    }
    height: auto;
  }

  .portfolio{
    p{
      display: none;
    }
   

    spam{

      font-size: 1rem;

    }

    .thumbs {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      background-color:#000000;
    
      a {
        display: inline-flex; // Make the <a> tag a flex container
        width: 100%; // Adjust the width based on your preferences, considering margins
        margin: 0px; // Add some margin for spacing
        img{
          opacity: 80%;
          filter: grayscale(0);
        }
      }
    }
  
  }
  
  
  footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p{
      font-size: 1.5rem;
    }
    h3{
      width: 80%;
     
      font-size: 1rem;
    }
    
  }
}




@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.gradient-text {
  /* Fallback: Set a background color. */
  background-color: #CA4246;
  
  /* Create the gradient. */
   background-image: linear-gradient(
        45deg,
        #CA4246 16.666%, 
        #E16541 16.666%, 
        #E16541 33.333%, 
        #F18F43 33.333%, 
        #F18F43 50%, 
        #8B9862 50%, 
        #8B9862 66.666%, 
        #476098 66.666%, 
        #476098 83.333%, 
        #A7489B 83.333%);
  
  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  
  /* Animate the text when loading the element. */
    /* This animates it on page load and when hovering out. */
    animation: rainbow-text-simple-animation-rev 0.75s ease forwards;

}

// .gradient-text:hover{
//     animation: rainbow-text-simple-animation 0.5s ease-in forwards;
// }


/* Move the background and make it smaller. */
/* Animation shown when entering the page and after the hover animation. */
@keyframes rainbow-text-simple-animation-rev {
    0% {
        background-size: 650%;
    }
    40% {
        background-size: 650%;
    }
    100% {
        background-size: 100%;
    }
}

/* Move the background and make it larger. */
/* Animation shown when hovering over the text. */
@keyframes rainbow-text-simple-animation {
    0% {
        background-size: 100%;
        
    }
    80% {
        background-size: 650%;
    }
    100% {
        background-size: 650%;
    }
}








.glitch{
  color:white;
  font-size:100px;
  position:relative;
  width:400px;
  margin:0 auto;
}
@keyframes noise-anim{
  $steps:20;
  @for $i from 0 through $steps{
    #{percentage($i*(1/$steps))}{
      clip:rect(random(100)+px,9999px,random(100)+px,0);
    }
  }
}
.glitch:after{
  content:attr(data-text);
  position:absolute;
  left:2px;
  text-shadow:-1px 0 red;
  top:0;
  color:white;
  background:black;
  overflow:hidden;
  clip:rect(0,900px,0,0); 
  animation:noise-anim 2s infinite linear alternate-reverse;
}

@keyframes noise-anim-2{
  $steps:20;
  @for $i from 0 through $steps{
    #{percentage($i*(1/$steps))}{
      clip:rect(random(100)+px,9999px,random(100)+px,0);
    }
  }
}
.glitch:before{
  content:attr(data-text);
  position:absolute;
  left:-2px;
  text-shadow:1px 0 blue; 
  top:0;
  color:white;
  background:black;
  overflow:hidden;
  clip:rect(0,900px,0,0); 
  animation:noise-anim-2 3s infinite linear alternate-reverse;
}


@keyframes shake {
  0%, 100% {
    transform: translateX(0);
    filter: blur(0px);
  }
  25%, 75% {
    transform: translateX(-1.5px);
    filter: blur(1px);
  }
  50% {
    transform: translateX(1.5px);
    filter: blur(-1px);
  }
}


.shaking-text {
  display: inline-block;
  animation: shake 0.2s infinite;

}

.noised{
 
  animation: flicker 10s infinite ;
}

// @keyframes flicker {
//   0%, 15%, 20%, 50%, 80%, 100% {
//     opacity: 1;
//   }
//   5%, 25%, 45%, 55%, 75% {
//     opacity: 0.4;
//   }
//   10%, 30%, 40%, 60%, 70% {
//     opacity: 0.8;
//   }
// }

@keyframes flicker {
  0% {
    opacity:0.1;
   
  }
  
  2% {
    opacity:1;
   
  }
  8% {
    opacity:0.1;
    
  }
  9% {
    opacity:1;
    
  }
  12% {
    opacity:0.1;
    
  }
  20% {
    opacity:1;
    transform: translateX(-1.5px);
  }
  21% {
    opacity:1;
    transform: translateX(1.5px);
  }
  25% {
    opacity:0.3;
  }
  30% {
    opacity:1;
   
  }
  
  70% {
    opacity:0.7;
  }
  
  72% {
    opacity:0.2;
    transform: translateY(-1.5px);
  }
  73% {
    opacity:0.2;
    transform: translateY(1.5px);
  }
  
  77% {
    opacity:.9;
  }
  100% {
    opacity:.9;
   
  }
}


iframe{
  width: 70%;
  border: 1px solid #cccccc;
  box-shadow: 0px 3.605555534362793px 27.04166603088379px rgba(0, 0, 0, 0.05);
  border-radius: 22px;
  height: 80vh;
}